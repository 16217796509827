import { map } from '../../api-service/apiMap';
import { ViewerSoftwareOptions, SoftwareOptionsForCompany } from '../../constants/enums.constants';
import { Environments } from '../../constants/environment.constants';
import { utils } from '../../utils';
import { handleRequest } from '../../api-service/apiService-helper';
import { eventBus } from '../../event-bus';
import { globalEventsKeys } from '../../event-bus/supportedKeys';
import { logToTimber, logToTimberBI, biMethods } from '../../timberLogger';
import { appSettingsManager } from '../../app-settings-manager';
import logger from '../../logger';
import { featureAvaliability } from '../../feature-avaliability/feature-avaliability-service';
import { apiConfig } from '../../api-service/apiConfig';
import { scannerVersionManager } from '../../scanner-version-manager/scanner-version-manager';

const serviceName = 'app-settings-service';

const { scannerMATApiMap } = apiConfig;

const { apiMapKeys } = map;

const { getOrderId, getCompanyId, getRxGuid, isScannerHostEnv } = utils;

const logToTimberAndBI = (error) => {
  logger
    .info('Network')
    .to(['analytics', 'host'])
    .data({ error, module: serviceName })
    .end();

  logToTimber({
    timberData: {
      action: `${serviceName} fetch resources`,
      module: serviceName,
      type: 'object',
      actor: 'System',
      value: {},
    },
  });

  logToTimberBI(
    biMethods.errorReportBiLog({
      object: serviceName,
      code: `${serviceName} error to fetch resources`,
      description: error.message,
      severity: 'Fetch settings failed',
    })
  );
};

const getRequestFunctionsInnerJoin = (functions, mapper) => {
  const innerJoinFunctions = {};

  Object.keys(mapper).forEach((key) => {
    if (!!functions[key]) {
      switch (key) {
        case 'getAllFlagsState':
          const { getAllFlagsState } = appSettingsManager.getAppSettings();

          const isScannerEnv = isScannerHostEnv();
          const scannerVersion = scannerVersionManager.getScannerVersion();
          const { v22B, v23A } = scannerVersionManager.scannerAssetsVersions;
          const isAvaliableForScanner = isScannerEnv && ![v22B, v23A].includes(scannerVersion);

          if (!getAllFlagsState && (isAvaliableForScanner || !isScannerEnv)) innerJoinFunctions[key] = functions[key];
          break;
        case 'getToolsAvailability':
          const isRulesMatrixEnabled = featureAvaliability.getIsRulesMatrixEnabled();
          if (isRulesMatrixEnabled) innerJoinFunctions[key] = functions[key];
          break;
        case 'getPatientOrdersForComparison':
          const isSideBySideCompareEnabled = featureAvaliability.isSideBySideCompareEnabled();
          const isTimeLapseEnabled = featureAvaliability.getIsTimeLapseEnabled();
          if (isSideBySideCompareEnabled || isTimeLapseEnabled) innerJoinFunctions[key] = functions[key];
          innerJoinFunctions[key] = functions[key];
          break;
        default:
          innerJoinFunctions[key] = functions[key];
          break;
      }
    }
  });
  return innerJoinFunctions;
};

export const requestFunctions = {
  environmentParametersSettings: {
    function: async () => {
      return await handleRequest({
        url: 'settings.json',
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
  localhostMockSettings: {
    function: async () => {
      return await handleRequest({
        url: 'localhost-mock.json',
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
  getFeaturesToggleSettings: {
    function: async () => {
      return await handleRequest({
        selector: apiMapKeys('getFeaturesToggleSettings'),
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
  getCompanySoftwareOptions: {
    function: async () => {
      const env = utils.getEnv();
      const requestParams = {
        selector: apiMapKeys('getCompanySoftwareOptions'),
        ...(env === Environments.EUP
          ? {
              queryParams: {
                companyId: getCompanyId(),
              },
            }
          : {}),
        module: serviceName,
      };

      return await handleRequest(requestParams);
    },
    isEnabledFor360Only: false,
  },
  getPatientOrdersForComparison: {
    function: async () => {
      const orderId = getOrderId();
      const rxGuid = getRxGuid();
      const params = rxGuid ? { orderId: orderId || 0, rxGuid } : { orderId };

      return await handleRequest({
        selector: apiMapKeys('getPatientOrdersForComparison'),
        queryParams: {
          ...params,
        },
        module: serviceName,
      });
    },
    isEnabledFor360Only: true,
  },
  areScannerSoftwareOptionsAvailable: {
    function: async () => {
      const softwareOptions = Object.values(ViewerSoftwareOptions)
        .concat(Object.values(SoftwareOptionsForCompany))
        .map((swo, index) => (index > 0 ? { softwareOptions: swo } : swo));

      return await handleRequest({
        selector: apiMapKeys('areScannerSoftwareOptionsAvailable'),
        queryParams: {
          softwareOptions,
          companyId: getCompanyId(),
        },
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
  getAllFlagsState: {
    function: async () => {
      const isScannerEnv = isScannerHostEnv();
      const companyId = getCompanyId();
      let requestParams = {
        selector: apiMapKeys('getAllFlagsState'),
        queryParams: companyId ? { companyId } : {},
        module: serviceName,
      };

      if (isScannerEnv) {
        const { web3dViewerBFFEndPoint } = appSettingsManager.getAppSettingsByValue('environmentParametersSettings');
        requestParams = {
          url: `${web3dViewerBFFEndPoint}/${scannerMATApiMap.getAllFlagsState.path}${
            companyId ? `?companyId=${companyId}` : ''
          }`,
          module: serviceName,
        };
      }

      return await handleRequest(requestParams);
    },
    isEnabledFor360Only: false,
  },
  getToolsAvailability: {
    function: async () => {
      const orderId = getOrderId();
      const rxGuid = getRxGuid();
      const params = rxGuid ? { orderId: orderId || 0, rxGuid } : { orderId };

      return await handleRequest({
        selector: apiMapKeys('getToolsAvailability'),
        queryParams: {
          ...params,
        },
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
    isEnabledForMidcOnly: true,
  },
};

export const requestSingle = async ({ selector }) => {
  const response = await Promise.resolve(requestFunctions[selector].function());
  if (response.status >= 200 && response.status < 300) {
    const fromPromiseResult = await response.json();
    appSettingsManager.addSetting(selector, fromPromiseResult);
    return fromPromiseResult;
  }

  return new Error(`requestSingle function failed to fetch ${selector} response status code: ${response.status}`);
};

export const appSettingServiceUtils = {
  delay: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
};

export const retryFailedPromises = async (failedPromises, result, requestFunctionsInnerJoin) => {
  const rulesMatrixFunctions = ['getPatientOrdersForComparison', 'getToolsAvailability'];
  for (const { key } of failedPromises) {
    if (rulesMatrixFunctions.includes(key)) {
      let success = false;
      const maxInitialRetries = 15;
      const maxTotalTime = 6 * 60 * 1000;
      const initialRetryInterval = 3000;
      const extendedRetryInterval = 15000;
      const startTime = Date.now();

      for (
        let attempt = 0;
        attempt <
          maxInitialRetries +
            Math.ceil((maxTotalTime - maxInitialRetries * initialRetryInterval) / extendedRetryInterval) && !success;
        attempt++
      ) {
        try {
          if (attempt < maxInitialRetries) {
            console.log(startTime, attempt);
            await appSettingServiceUtils.delay(initialRetryInterval);
          } else {
            await appSettingServiceUtils.delay(extendedRetryInterval);
          }

          const response = await requestFunctionsInnerJoin[key].function();
          if (response.status >= 200 && response.status < 300) {
            const json = await response.json();
            result[key] = json;
            success = true;
            break;
          } else {
            throw new Error(`Request failed with status ${response.status}`);
          }
        } catch (error) {
          if (Date.now() - startTime >= maxTotalTime) {
            logToTimberAndBI(error);
            return Promise.reject(error);
          }
        }
      }
    }
  }
};

export const getAllSettings = async () => {
  try {
    const promiseArray = [];
    const is360hub = utils.getIs360HubEnabled();
    const isScanner = utils.getEnv() === Environments.SCANNER;
    const requestFunctionsInnerJoin = getRequestFunctionsInnerJoin(requestFunctions, apiMapKeys());

    Object.entries(requestFunctionsInnerJoin).forEach(([key, requestFunction]) => {
      const isFunctionEnabledForContext =
        !(isScanner && requestFunction.isEnabledForMidcOnly) &&
        ((is360hub && requestFunction.isEnabledFor360Only) || !requestFunction.isEnabledFor360Only);
      isFunctionEnabledForContext && promiseArray.push({ [key]: requestFunction.function() });
    });

    const createResultObject = async () => {
      const resolvedPromises = await Promise.allSettled(
        promiseArray.map((promise) => promise[Object.keys(promise)[0]])
      );

      const result = {};
      const failedPromises = [];

      for (let index = 0; index < resolvedPromises.length; index++) {
        const { status, value, reason } = resolvedPromises[index];
        const key = Object.keys(promiseArray[index])[0];

        if (status === 'fulfilled' && value.status >= 200 && value.status < 300) {
          try {
            const json = await value.json();
            result[key] = json;
          } catch (error) {
            logToTimberAndBI(error);
            return Promise.reject(error);
          }
        } else {
          failedPromises.push({ key, reason });
        }
      }

      await retryFailedPromises(failedPromises, result, requestFunctionsInnerJoin);
      return result;
    };

    const fromPromiseResults = await createResultObject();

    return Promise.resolve(fromPromiseResults);
  } catch (error) {
    const { errorMessage, selector, url } = error || {};
    error.message = errorMessage && (selector || url) ? `${errorMessage}, ${selector} ${url}` : error.message;
    logToTimberAndBI(error);
    return Promise.reject(error);
  }
};

export default {
  getAllSettings,
  requestSingle,
};
