import { utils, cacheManager, cacheKeys, scannerVersionManager, AOHSService } from '@web-3d-tool/shared-logic';

const { isScannerHostEnv, formatDateAndTime, getIs360HubEnabled } = utils;

const getModelCompareDate = (orderKey) => {
  const dateObj = cacheManager.get(cacheKeys.SELECTED_ORDER_DATE) || {};
  return formatDateAndTime(dateObj[orderKey])?.formattedDate;
};

const getSplitTranslation = (split, dualView) => {
  const lowerTranslation = dualView?.lower ?? 'Lower';
  const upperTranslation = dualView?.upper ?? 'Upper';
  return split === 0 ? upperTranslation : lowerTranslation;
};

export const getWidth = (
  propsArr,
  is360,
  isSplittedViewWithSidePluginActive,
  imageFrameDimentions,
  isModelCompareActive
) => {
  if (!isModelCompareActive && isSplittedViewWithSidePluginActive && is360) {
    return `calc(( 100vw - ${imageFrameDimentions?.width}px + ${imageFrameDimentions?.drawerWidth}px ) / ${propsArr.length}  )`;
  }

  return '100%';
};

export const getCurrentModelAlias = ({ split, propsArrLength, isModelCompareActive, compareRowIndex, dualView }) => {
  if (propsArrLength === 1) {
    return getModelCompareDate('current');
  }

  if (propsArrLength === 2) {
    return isModelCompareActive
      ? getModelCompareDate(split === compareRowIndex ? 'selected' : 'current')
      : getSplitTranslation(split, dualView);
  }
};

export const isCurrentModelAliasAllowed = (propsArrLength, isModelCompareActive, isGalleryViewActive) => {
  const is360 = getIs360HubEnabled();
  const isScannerHost = isScannerHostEnv();
  const scannerVersion = scannerVersionManager.getScannerVersion();
  const is22BScannerVersion = isScannerHost && scannerVersion === scannerVersionManager.scannerAssetsVersions.v22B;

  const hasCurrentDateData = getModelCompareDate('current') !== 'Invalid date';

  if (isGalleryViewActive) return false;
  if (!is360) return false;
  if (is22BScannerVersion) return propsArrLength === 2 && !isModelCompareActive;

  if (hasCurrentDateData) {
    const isAOHS = utils.isAOHSEnv();
    const isAOHSInConditionMode = AOHSService.isAOHSInConditionMode();

    if (propsArrLength === 1) {
      if (isAOHS) return isAOHSInConditionMode;
      return true;
    }

    if (propsArrLength === 2) return true;
  } else {
    return propsArrLength === 2 && !isModelCompareActive;
  }

  return false;
};
