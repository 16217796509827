import { appSettingsManager } from '../app-settings-manager';
import { scannerVersionManager } from '../scanner-version-manager/scanner-version-manager';
import { utils } from '../utils';
import { ViewerSoftwareOptions } from '../constants/enums.constants';

const getAppSettingsFromCache = () => appSettingsManager.getAppSettings();

export const getIsIntraOralCameraEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    const currentScannerVersion = utils.getScannerPackageVersion();
    const isScannerOfVersion = scannerVersionManager.checkScannerPackageVersions(currentScannerVersion, [{ major: 2 }]);
    return isScanner && isScannerOfVersion && scannerSwo;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.ShouldEnableIntraoralCameraTool,
    resolveFunction,
  });
};

export const getIsNGNiriEnabledForLuminaPro = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    const currentScannerVersion = utils.getScannerPackageVersion();
    const isScannerOfVersion = scannerVersionManager.checkScannerPackageVersions(currentScannerVersion, [{ major: 4 }]);
    return isScanner && isScannerOfVersion && scannerSwo;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.NGNIRI,
    resolveFunction,
  });
};

export const getAvailbilityForPluginOrFeature = ({ featureFlagNames, companySwo, scannerSwo, resolveFunction }) => {
  const {
    areScannerSoftwareOptionsAvailable,
    getCompanySoftwareOptions,
    getFeaturesToggleSettings,
    getAllFlagsState,
  } = getAppSettingsFromCache();
  const isScannerSwoEnabled = !!(
    areScannerSoftwareOptionsAvailable &&
    areScannerSoftwareOptionsAvailable.hasOwnProperty(scannerSwo) &&
    areScannerSoftwareOptionsAvailable[scannerSwo] === true
  );
  const isCompanySwoEnabled = getCompanySoftwareOptions && getCompanySoftwareOptions.includes(companySwo);
  const isFeatureFlagEnabled = getAllFlagsState
    ? getAllFlagsState[featureFlagNames[0]]
    : !!(
        getFeaturesToggleSettings &&
        getFeaturesToggleSettings.find((ff) => featureFlagNames.includes(ff.id) && ff.isActive)
      );
  return resolveFunction(isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled);
};

export const getPendoFeatureFlags = (pendoPrefix, getAllFlagsState) => {
  if (!getAllFlagsState || !pendoPrefix || pendoPrefix.length === 0) {
    return [];
  }
  return Object.keys(getAllFlagsState)
    .filter((key) => key.startsWith(pendoPrefix) && getAllFlagsState[key])
    .map((key) => key);
};

export const getIsIntraOralCameraAndNiriEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    const currentScannerVersion = utils.getScannerPackageVersion();
    const isScannerOfVersion = scannerVersionManager.checkScannerPackageVersions(currentScannerVersion, [{ major: 2 }]);
    return isScanner && isScannerOfVersion && scannerSwo;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.ShouldEnableEvx,
    resolveFunction,
  });
};

export const shouldEnableEvx = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return scannerSwo;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.ShouldEnableEvx,
    resolveFunction,
  });
};

export const getIsNGNiriHidden = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    const currentScannerVersion = utils.getScannerPackageVersion();
    const isScannerNGCore = scannerVersionManager.checkScannerPackageVersions(currentScannerVersion, [{ major: 3 }]);
    return isScanner && isScannerNGCore;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.NGNIRI,
    resolveFunction,
  });
};

export const getIsNGCoreScanner = () => {
  const isScanner = utils.isScannerHostEnv();
  const currentScannerVersion = utils.getScannerPackageVersion();
  const isScannerNGCore = scannerVersionManager.checkScannerPackageVersions(currentScannerVersion, [{ major: 3 }]);

  return isScanner && isScannerNGCore;
};
