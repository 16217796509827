import React, { useEffect, useState } from 'react';
import {
  utils,
  eventBus,
  globalEventsKeys,
  cacheKeys,
  cacheManager,
  featureAvaliability,
} from '@web-3d-tool/shared-logic';
import styles from './Splitter.module.css';
import styles360 from './Splitter360.module.css';
import { getWidth, isCurrentModelAliasAllowed, getCurrentModelAlias } from './Splitter.logic';

export const handleOnChangeClick = (setChangeClicked) => {
  eventBus.raiseEvent(globalEventsKeys.TOGGLE_CHANGE_SCAN_BUTTON);
  setChangeClicked((prev) => !prev);
};

export const getClassName = (is360, isAOHS, propsMatrixLength) => {
  if (is360) {
    return !isAOHS && propsMatrixLength > 1 ? styles360.containerWithSplit : styles360.container;
  }
  return styles.container;
};

export const shouldRenderChangeButton = (isModelCompareActive, getPatientOrdersForComparison, compareRowIndex, j) => {
  return isModelCompareActive && getPatientOrdersForComparison.length > 2 && compareRowIndex === j;
};

export const getButtonClass = (shouldRenderButton, changeClicked) => {
  const buttonClass = shouldRenderButton ? (changeClicked ? styles360.changeClicked : styles360.withChangedButton) : '';
  return buttonClass;
};

const Splitter = (props) => {
  const {
    renderComp,
    propsMatrix,
    isModelCompareActive,
    imageFrameDimentions = { width: window.innerWidth / 4 + 32, drawerWidth: 32 },
    isSplittedViewWithSidePluginActive,
    translationsForSplitter,
    isOnLanding,
    compareRowIndex,
  } = props;

  const { getIs360HubEnabled, isAOHSEnv } = utils;
  const is360 = getIs360HubEnabled();
  const isAOHS = isAOHSEnv();
  const isChangeComparedScanButtonEnabled = featureAvaliability.getIsChangeComparedScanButtonEnabled();
  const margin = '0 auto';
  const propsLength = propsMatrix.flat().length;
  const isGalleryViewActive = propsLength === 5;
  const isDualViewActive = propsLength === 2;

  const [changeClicked, setChangeClicked] = useState(false);
  const [, setWindowSizeReset] = useState({});
  const { getPatientOrdersForComparison } = cacheManager.get(cacheKeys.APP_SETTINGS) || {};
  const { dualView, ScanSelector } = translationsForSplitter;

  useEffect(() => {
    const windowResize = () => {
      setWindowSizeReset({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', windowResize);
    eventBus.subscribeToEvent(globalEventsKeys.CHANGE_SCAN_BUTTON_STATE, () => {
      setChangeClicked((prev) => !prev);
    });
  }, []);

  return (
    <div className={getClassName(is360, isAOHS, propsMatrix.length)} id="splitterDiv">
      {propsMatrix.map((propsArr, i) => (
        <div
          key={i}
          className={is360 ? styles360.splitterRow : styles.splitterRow}
          style={{
            '--marginTop': `${
              i === 0 && (isGalleryViewActive || (isDualViewActive && !isModelCompareActive)) ? 34 : 0
            }px`,
            height: `${100 / propsMatrix.length}%`,
          }}
        >
          {propsArr.map((componentProps, j) => {
            const shouldRenderButton =
              isChangeComparedScanButtonEnabled &&
              shouldRenderChangeButton(isModelCompareActive, getPatientOrdersForComparison, compareRowIndex, j);
            const buttonClass = getButtonClass(shouldRenderButton, changeClicked);
            return (
              <div
                style={{
                  width: getWidth(
                    propsArr,
                    is360,
                    isSplittedViewWithSidePluginActive,
                    imageFrameDimentions,
                    isModelCompareActive
                  ),
                  height: '100%',
                  ...(isOnLanding ? { margin: margin } : {}),
                }}
                key={j}
              >
                {renderComp(componentProps, i, j)}
                {isCurrentModelAliasAllowed(propsArr.length, isModelCompareActive, isGalleryViewActive) && (
                  <div
                    className={`${styles360.splitterRowSign} ${buttonClass}`}
                    onClick={shouldRenderButton ? () => handleOnChangeClick(setChangeClicked) : null}
                    style={{
                      cursor: shouldRenderButton ? 'pointer' : 'default',
                    }}
                  >
                    {getCurrentModelAlias({
                      split: j,
                      propsArrLength: propsArr.length,
                      isModelCompareActive,
                      compareRowIndex,
                      dualView,
                    })}
                    {shouldRenderButton && <span className={styles.changeButton}>{ScanSelector.change}</span>}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Splitter;
